import { createTheme } from '@mui/material/styles';
import { deepOrange, grey, blue } from '@mui/material/colors';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: '#ffffff',
            paper: '#f5f5f5',
        },
        primary: {
            main: blue[500],
        },
        secondary: {
            main: grey[600],
        },
        text: {
            primary: '#000000',
            secondary: '#555555',
        },
    },
    typography: {
        allVariants: {
            color: '#000000',
        },
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1d1d1d',
        },
        primary: {
            main: deepOrange[500],
        },
        secondary: {
            main: grey[500],
        },
        text: {
            primary: '#ffffff',
            secondary: '#b3b3b3',
        },
    },
    typography: {
        allVariants: {
            color: '#ffffff',
        },
    },
});

export { lightTheme, darkTheme };
