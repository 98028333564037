import React, { Component } from 'react';
import { Container, TextField, Button, MenuItem, Typography } from '@mui/material';

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      address: '',
      paymentMethod: '',
    };
  }

  handleOrder = (event) => {
    event.preventDefault();
    console.log('Order placed:', this.state);
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    console.log('Checkout page');
    return (
      <Container style={{ marginTop: '30px' }}>
        <Typography variant="h4" gutterBottom>
          Checkout
        </Typography>
        <form onSubmit={this.handleOrder}>
          <TextField
            label="Name"
            name="name"
            fullWidth
            margin="normal"
            value={this.state.name}
            onChange={this.handleChange}
          />
          <TextField
            label="Address"
            name="address"
            fullWidth
            margin="normal"
            value={this.state.address}
            onChange={this.handleChange}
          />
          <TextField
            select
            label="Payment Method"
            name="paymentMethod"
            fullWidth
            margin="normal"
            value={this.state.paymentMethod}
            onChange={this.handleChange}
          >
            <MenuItem value="credit-card">Credit Card</MenuItem>
            <MenuItem value="paypal">PayPal</MenuItem>
          </TextField>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '20px' }}
            onClick={this.props.handlePlaceOrder} // Call this on click
          >
            Place Order
          </Button>
        </form>
      </Container>
    );
  }
}

export default Checkout;
            