import React, { Component } from 'react';
import { Container, Typography, Switch, FormControlLabel, Button, TextField, Grid2 } from '@mui/material';

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: this.props.darkMode,
      username: 'John Doe',
      email: 'johndoe@example.com',
    };
  }

  handleThemeToggle = (event) => {
    this.setState({ darkMode: event.target.checked });
    this.props.toggleTheme(event.target.checked);
  };

  handleAccountChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    return (
      <Container>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={this.state.darkMode}
              onChange={this.handleThemeToggle}
              name="darkMode"
              color="primary"
            />
          }
          label="Dark Mode"
        />

        <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>
          Account Settings
        </Typography>
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <TextField
              fullWidth
              label="Username"
              value={this.state.username}
              onChange={(e) => this.handleAccountChange('username', e.target.value)}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <TextField
              fullWidth
              label="Email"
              value={this.state.email}
              onChange={(e) => this.handleAccountChange('email', e.target.value)}
            />
          </Grid2>
        </Grid2>

        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: '20px' }}
        >
          Sign Out
        </Button>
      </Container>
    );
  }
}

export default SettingsPage;
