import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import Home from './pages/Home';
import MenuWithCart from './pages/MenuWithCart';
import Checkout from './components/Checkout';
import KitchenAdmin from './pages/KitchenAdmin';
import Header from './components/Header';
import SettingsPage from './pages/SettingsPage';
import { lightTheme, darkTheme } from './theme'; // Themes

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: true, // Default to dark mode
    };
  }

  toggleTheme = (isDark) => {
    this.setState({ darkMode: isDark });
  };

  render() {
    const theme = this.state.darkMode ? darkTheme : lightTheme;

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menu" element={<MenuWithCart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/admin" element={<KitchenAdmin />} />
            <Route path="/settings" element={<SettingsPage darkMode={this.state.darkMode} toggleTheme={this.toggleTheme} />} />
          </Routes>
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
