import React, { Component } from 'react';
import { AppBar, Select, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };

  }

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  handleChange = (event) => {
    // this.props.setSelectedName(event.target.value);
  };

  render() {
    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    let { selectedName } = this.props;
    selectedName = selectedName || "Kelvin"


    return (
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1, fontWeight: "bold" }}>
            CasaBella
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={this.handleMenuClick}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Select
            defaultValue={selectedName}
            onChange={this.handleChange}
            style={{ color: 'white'}}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <MenuItem value="Kelvin">Kelvin</MenuItem>
            <MenuItem value="Happy">Happy</MenuItem>
            <MenuItem value="Nana Kwame">Nana Kwame</MenuItem>
            <MenuItem value="One Per">One Per</MenuItem>
          </Select>

          <Button color="inherit" component={Link} to="/" sx={{ display: { xs: 'none', md: 'block' } }}>
            Home
          </Button>
          <Button color="inherit" component={Link} to="/menu" sx={{ display: { xs: 'none', md: 'block' } }}>
            Menu
          </Button>
          <Button color="inherit" component={Link} to="/cart" sx={{ display: { xs: 'none', md: 'block' } }}>
            Cart
          </Button>
          <Button color="inherit" component={Link} to="/admin" sx={{ display: { xs: 'none', md: 'block' } }}>
            Kitchen Admin
          </Button>
          <Button color="inherit" component={Link} to="/settings" sx={{ display: { xs: 'none', md: 'block' } }}>
            Settings
          </Button>

          {/* Mobile Menu */}
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={this.handleMenuClose}
          >
            <Select
              value={selectedName}
              onChange={this.handleChange}
              style={{ color: 'white', maxHeight: '3em' }}
            >
              <MenuItem value="Kelvin">Kelvin</MenuItem>
              <MenuItem value="Happy">Happy</MenuItem>
              <MenuItem value="Nana Kwame">Nana Kwame</MenuItem>
              <MenuItem value="One Per">One Per</MenuItem>
            </Select>
            <MenuItem onClick={this.handleMenuClose} component={Link} to="/">Home</MenuItem>
            <MenuItem onClick={this.handleMenuClose} component={Link} to="/menu">Menu</MenuItem>
            <MenuItem onClick={this.handleMenuClose} component={Link} to="/cart">Cart</MenuItem>
            <MenuItem onClick={this.handleMenuClose} component={Link} to="/admin">Kitchen Admin</MenuItem>
            <MenuItem onClick={this.handleMenuClose} component={Link} to="/settings">Settings</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Header;
