import React, { Component } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Button, Typography } from '@mui/material';
import axios from 'axios';

class KitchenAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
    };
  }

  componentDidMount() {
    // Fetch the orders from an API (placeholder)
    // TODO: enable this later
    // axios.get('/api/orders')
    //   .then(response => this.setState({ orders: response.data }))
    //   .catch(error => console.error("Error fetching orders:", error));
    //   this.setState({ orders: [
    //     {"name": "Gari", id: 1, items: ["Pizza", "Burger"], status: "Pending" },
    //   ] })
  }

  handleStatusChange = (orderId, event) => {
    const updatedOrders = this.state.orders.map(order => {
      if (order.id === orderId) {
        return { ...order, status: event.target.value };
      }
      return order;
    });
    this.setState({ orders: updatedOrders });
  };

  handleUpdateOrder = (orderId) => {
    const order = this.state.orders.find(order => order.id === orderId);
    console.log("Updating order:", order);
    // Simulate an API call to update the order status
    axios.put(`/api/orders/${orderId}`, { status: order.status })
      .then(response => console.log("Order updated:", response))
      .catch(error => console.error("Error updating order:", error));
  };

  render() {
    return (
      <Container style={{ marginTop: '30px' }}>
        <Typography variant="h4" gutterBottom>
          Kitchen Admin - Orders
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Items</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>{order.customerName}</TableCell>
                  <TableCell>
                    <ul>
                      {order.items.map((item, index) => (
                        <li key={index}>{item.name} (x{item.quantity})</li>
                      ))}
                    </ul>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={order.status}
                      onChange={(event) => this.handleStatusChange(order.id, event)}
                    >
                      <MenuItem value="Received">Received</MenuItem>
                      <MenuItem value="In Progress">In Progress</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleUpdateOrder(order.id)}
                    >
                      Update Order
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}

export default KitchenAdmin;
