import React, { Component } from 'react';
import { Button, Typography, Container } from '@mui/material';
import { Link } from 'react-router-dom';

class Home extends Component {
  render() {
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h3" gutterBottom>
          Welcome to Our Food Ordering App
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/menu">
          Order Now
        </Button>
      </Container>
    );
  }
}

export default Home;
            