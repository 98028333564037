import React, { Component } from 'react';
import { Container,  Grid2, Paper, Typography, Button, List, ListItem, ListItemText, TextField, Dialog, DialogContent, DialogTitle, Snackbar, Badge, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkout from '../components/Checkout';

class MenuWithCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: [],
      searchQuery: '',
      promoCode: '',
      discount: 0,
      menuItems: [
        { name: 'Pizza', price: 10.00 },
        { name: 'Burger', price: 8.00 },
        { name: 'Pasta', price: 12.00 },
        { name: 'Salad', price: 7.00 },
      ],
      isCheckoutOpen: false, // For opening/closing checkout dialog
      snackbarOpen: false, // To show notifications
      snackbarMessage: '', // Notification message
    };
  }

  // Function to handle adding an item to the cart
  addToCart = (item) => {
    const existingItem = this.state.cart.find(cartItem => cartItem.name === item.name);
    
    if (existingItem) {
      // If item exists in the cart, update its quantity
      this.setState((prevState) => ({
        cart: prevState.cart.map(cartItem =>
          cartItem.name === item.name ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
        )
      }));
    } else {
      // Add new item to the cart with quantity 1
      this.setState((prevState) => ({
        cart: [...prevState.cart, { ...item, quantity: 1 }]
      }));
    }
  };

  // Function to handle search input change
  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  // Function to filter menu items based on search query
  getFilteredMenuItems = () => {
    const { searchQuery, menuItems } = this.state;
    return menuItems.filter(item =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  // Open/close the checkout dialog
  handleOpenCheckout = () => {
    this.setState({ isCheckoutOpen: true });
  };

  handleCloseCheckout = () => {
    this.setState({ isCheckoutOpen: false });
  };

  // Simulate backend order placement and show notification
  handlePlaceOrder = () => {
    setTimeout(() => {
      // Simulate success
      this.setState({
        isCheckoutOpen: false, // Close the checkout dialog
        snackbarOpen: true, // Show notification
        snackbarMessage: 'Order placed successfully!', // Success message
        cart: [] // Clear the cart after order placement
      });
    }, 1000); // Simulating 1 second delay for the backend call
  };

  // Close snackbar notification
  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  // Handle quantity change in the cart
  handleQuantityChange = (event, item) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (newQuantity >= 0) {
      this.setState((prevState) => ({
        cart: prevState.cart.map(cartItem =>
          cartItem.name === item.name ? { ...cartItem, quantity: newQuantity } : cartItem
        )
      }));
    }
  };

  // Delete item from cart
  handleDeleteItem = (item) => {
    this.setState((prevState) => ({
      cart: prevState.cart.filter(cartItem => cartItem.name !== item.name)
    }));
  };

  // Calculate subtotal
  calculateSubtotal = () => {
    return this.state.cart.reduce((total, item) => total + (item.price * item.quantity), 0);
  };

  // Apply promo code
  applyPromoCode = () => {
    const validPromoCodes = {
      'SAVE10': 10, // 10% discount
      'SAVE20': 20  // 20% discount
    };

    const discount = validPromoCodes[this.state.promoCode.toUpperCase()] || 0;
    this.setState({ discount });
  };

  // Calculate total with taxes, fees, and promo code
  calculateTotal = () => {
    const subtotal = this.calculateSubtotal();
    const taxRate = 0.08; // 8% tax
    const additionalFees = 5; // Fixed additional fee

    const taxes = subtotal * taxRate;
    const discountAmount = (this.state.discount / 100) * subtotal;
    const total = subtotal + taxes + additionalFees - discountAmount;

    return total.toFixed(2); // Return total rounded to 2 decimal places
  };

  render() {
    const filteredMenuItems = this.getFilteredMenuItems();
    const cartItemCount = this.state.cart.length;
    const subtotal = this.calculateSubtotal();
    const taxRate = 0.08; // 8% tax
    const additionalFees = 5; // Fixed additional fee
    const taxes = subtotal * taxRate;
    const total = this.calculateTotal();

    return (
      <Container>
        <Grid2 container spacing={3} style={{ marginTop: '20px' }}>
          {/* Menu Section */}
          <Grid2 size={{ xs: 12, md: 8 }}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Grid2 container justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                  Menu
                </Typography>
                {/* Notification Badge for Cart */}
                <IconButton color="inherit">
                  <Badge badgeContent={cartItemCount} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              </Grid2>

              {/* Search Input */}
              <TextField
                label="Search Menu"
                variant="outlined"
                fullWidth
                value={this.state.searchQuery}
                onChange={this.handleSearchChange}
                style={{ marginBottom: '20px' }}
              />

              {/* Filtered Menu Items */}
              <List>
                {filteredMenuItems.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={item.name} secondary={`$${item.price.toFixed(2)}`} />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.addToCart(item)}
                    >
                      Add to Cart
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid2>

          {/* Cart Section */}
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h4" gutterBottom>
                Cart
              </Typography>
              <List>
                {this.state.cart.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={item.name}
                      secondary={`Price: $${item.price.toFixed(2)}, Quantity: ${item.quantity}`}
                    />
                    <TextField
                      type="number"
                      value={item.quantity}
                      onChange={(event) => this.handleQuantityChange(event, item)}
                      inputProps={{ min: "0", style: { width: '60px' } }}
                    />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => this.handleDeleteItem(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>

              {/* Promo Code Input */}
              <TextField
                label="Promo Code"
                variant="outlined"
                fullWidth
                value={this.state.promoCode}
                onChange={(e) => this.setState({ promoCode: e.target.value })}
                style={{ marginBottom: '10px' }}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={this.applyPromoCode}
                style={{ marginBottom: '20px' }}
              >
                Apply Promo Code
              </Button>

              {/* Cart Summary */}
              <Typography variant="body1">Subtotal: ${subtotal.toFixed(2)}</Typography>
              <Typography variant="body1">Taxes (8%): ${taxes.toFixed(2)}</Typography>
              <Typography variant="body1">Additional Fees: ${additionalFees.toFixed(2)}</Typography>
              <Typography variant="body1" color="secondary">Promo Discount: -${(this.state.discount / 100 * subtotal).toFixed(2)}</Typography>
              <Typography variant="h5">Total: ${total}</Typography>

              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={this.handleOpenCheckout}
              >
                Checkout
              </Button>
            </Paper>
          </Grid2>
        </Grid2>

        {/* Checkout Dialog */}
        <Dialog
          open={this.state.isCheckoutOpen}
          onClose={this.handleCloseCheckout}
        >
          <DialogTitle>Checkout</DialogTitle>
          <DialogContent>
            <Checkout handlePlaceOrder={this.handlePlaceOrder} />
          </DialogContent>
        </Dialog>

        {/* Snackbar Notification */}
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
          message={this.state.snackbarMessage}
        />
      </Container>
    );
  }
}

export default MenuWithCart;
